import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Bench Press 3×12`}</p>
    <p>{`DB Pullovers 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time:`}</p>
    <p>{`20-Ring Push-ups`}</p>
    <p>{`20-Pull-ups`}</p>
    <p>{`20-V Ups`}</p>
    <p>{`200m Run`}</p>
    <p>{`*`}{`We will have a free partner wod for Labor Day on Monday at 9:00 &
10:30am so bring a friend! All other class times are cancelled for the
day.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      